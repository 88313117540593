import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {EmployeeLite} from 'app/blocks/model/site-visit.model';
import {DiTime} from 'app/blocks/util/di-time';

export interface IDriverEodReport extends IBaseEntity {
    id?: number;
    startingCash?: number;
    cashCollected?: number;
    cashDeposited?: number;
    endingCash?: number;
    reportDate?: string;
    reportTime?: any;
    utcTime?: number;
    driver?: EmployeeLite;
    paymentType?: string;
    driverTransactionType?: string;
}

export class DriverEodReport extends BaseEntity implements IDriverEodReport {
    public id?: number;
    public startingCash?: number;
    public cashCollected?: number;
    public cashDeposited?: number;
    public endingCash?: number;
    public reportDate?: string;
    public reportTime?: any;
    public utcTime?: number;
    public driver?: EmployeeLite;
    public paymentType?: string;
    public driverTransactionType?: string;

    constructor(report?) {
        super();
        if (report) {
            this.id = report.id;
            this.startingCash = report.startingCash;
            this.cashCollected = report.cashCollected;
            this.cashDeposited = report.cashDeposited;
            this.endingCash = report.endingCash;
            this.reportDate = report.reportDate;
            this.reportTime = DiTime.newInstance(report.reportTime);
            this.utcTime = report.utcTime;
            this.driver = report.driver;
            this.paymentType = report.paymentType;
            this.driverTransactionType = report.driverTransactionType;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DriverEodReport {
        return new DriverEodReport(this);
    }
}
